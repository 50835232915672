






































































































































::v-deep {
  .q-timeline__subtitle {
    opacity: 1;
  }

  .destination-q-timeline-entry {
    .q-timeline__content {
      margin-top: -8px;
    }
  }
}
